<div class="loading-container" *ngIf="loading">
  <nz-spin nzSize="large"></nz-spin>
</div>
<div class="container" *ngIf="!loading">
  <div class="button-wrapper">
    <button
      nz-button
      nzType="primary"
      class="add-button mr-2"
      nz-popconfirm
      nzPopconfirmTitle="Are you sure to delete?"
      nzPopconfirmPlacement="bottom"
      (nzOnConfirm)="confirm()"
      (nzOnCancel)="cancel()"
      *ngIf="isSuperadmin()"
    >
      <i nz-icon nzType="delete" theme="outline"></i>
      <span class="m-l-5">Delete</span>
    </button>

    <button
      nz-button
      nzType="primary"
      class="add-button"
      (click)="printInvoice()"
    >
      <i nz-icon nzType="save" nzTheme="outline"></i>
      Print
    </button>
  </div>
  <div class="container" id="test">
    <nz-card>
      <div id="invoice" class="p-h-30">
        <div class="m-t-15 lh-2">
          <div class="inline-block">
            <img
              class="img-fluid"
              src="{{ invoice?.organization?.logo_url }}"
              alt=""
            />
            <address class="p-l-10">
              <span class="font-weight-semibold text-dark">{{
                invoice?.organization?.name
              }}</span
              ><br />
              <span class="font-weight-semibold text-dark">
                {{ invoice?.organization?.name_ar }} </span
              ><br />
              <span>{{ invoice?.organization?.location }}</span
              ><br />
              <abbr class="text-dark">Phone: </abbr>
              <span>{{ invoice?.organization?.contact?.phone_number }}</span
              ><br />
              <abbr class="text-dark">WhatsApp: </abbr>
              <span>{{ invoice?.organization?.contact?.whatsapp }}</span>
              <br />
              <abbr class="text-dark">TRN: </abbr>
              <span>{{ invoice?.organization?.tax_registration_number }}</span>
            </address>
          </div>
          <div class="float-right">
            <h2>TAX INVOICE</h2>
            <h2 style="float: right">فاتورة ضريبية</h2>
          </div>
        </div>
        <div class="row m-t-20 lh-2">
          <div
            class="col-sm-8"
            style="float: left"
            *ngIf="invoice?.invoiced_to?.contact"
          >
            <h3 class="p-l-10 m-t-10">Invoice To:</h3>
            <address class="mt-3 p-l-10 text-dark" style="font-size: small">
              <div class="text-dark">
                <h5 class="font-weight-semibold">
                  {{ invoice?.invoiced_to?.contact?.first_name | titlecase }}
                  {{ invoice?.invoiced_to?.contact?.last_name | titlecase }}
                </h5>
                <div class="info-item">
                  <span class="font-weight-semibold">Phone: </span>
                  <span class="info-content">{{
                    invoice?.invoiced_to?.contact?.phone_number
                  }}</span>
                </div>
                <div class="info-item">
                  <span class="font-weight-semibold">Emirates ID: </span>
                  <span class="info-content">{{
                    invoice?.invoiced_to?.emirates_id
                  }}</span>
                </div>
                <div class="info-item">
                  <span class="font-weight-semibold">Student ID: </span>
                  <span class="info-content">{{
                    invoice?.invoiced_to?.id
                  }}</span>
                </div>
                <div class="info-item">
                  <span class="font-weight-semibold">License Type: </span>
                  <span class="info-content">
                    {{
                      invoice?.invoiced_to?.license_type === 0
                        ? "Automatic"
                        : invoice?.invoiced_to?.license_type === 1
                        ? "Manual"
                        : "Bike"
                    }}
                  </span>
                </div>
                <div
                  class="info-item"
                  *ngIf="
                    invoice?.invoiced_to?.trainer?.first_name ||
                    invoice?.invoiced_to?.trainer?.last_name
                  "
                >
                  <span class="font-weight-semibold">Trainer: </span>
                  <span class="info-content">
                    {{ toTitleCase(invoice?.invoiced_to?.trainer?.first_name) }}
                    {{ toTitleCase(invoice?.invoiced_to?.trainer?.last_name) }}
                    (&#9742;
                    {{ invoice?.invoiced_to?.trainer?.phone_number }})
                  </span>
                </div>
              </div>
            </address>
          </div>
          <div class="col-sm-4" style="color: #323239 !important">
            <div class="m-t-80">
              <div class="mb-0">
                <span class="font-weight-semibold text-dark">Serial No: </span>
                <span>{{ invoice?.invoice_number }}</span>
              </div>
              <div class="mb-0">
                <span class="font-weight-semibold text-dark">Date: </span>
                <span>{{
                  invoice?.created_at | date : "dd-MM-yyyy hh:mm a"
                }}</span>
              </div>
              <div class="mb-0" *ngIf="invoice?.description">
                <span class="font-weight-semibold text-dark">Test Date: </span>
                <span>{{ invoice?.description | date : "dd-MM-yyyy" }}</span>
              </div>
              <div class="mb-0">
                <span class="font-weight-semibold text-dark">Invoice No: </span>
                <span>{{ invoice?.invoice_no }}</span>
              </div>
              <div class="mb-0" *ngIf="invoice?.referred_by">
                <span class="font-weight-semibold text-dark"
                  >Referred by:
                </span>
                <span>
                  {{ toTitleCase(invoice.referred_by.contact.first_name) }}
                  {{ toTitleCase(invoice.referred_by.contact.last_name) }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-t-20">
          <div class="col-sm-12">
            <nz-table
              #orderTable
              [nzData]="itemData"
              [nzShowPagination]="false"
            >
              <thead>
                <tr class="text-start">
                  <th class="text-center">No.</th>
                  <th class="text-center">Particular</th>
                  <th class="text-center">TCN</th>
                  <th class="text-center">Quantity</th>
                  <th class="text-center">Price</th>
                  <th class="text-center">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of orderTable.data; let i = index">
                  <td class="text-center">{{ i + 1 }}</td>
                  <td class="text-center">{{ item.title | uppercase }}</td>
                  <td class="text-center">{{ item.description }}</td>
                  <td class="text-center">{{ item.quantity }}</td>
                  <td class="text-center">
                    {{ item.cost + item.profit | number : "1.2-5" }}
                    <small>AED</small>
                  </td>
                  <td class="text-center">
                    {{
                      item.quantity * (item.cost + item.profit)
                        | number : "1.2-5"
                    }}
                    <small>AED</small>
                  </td>
                </tr>
              </tbody>
            </nz-table>
            <div class="row m-t-30 lh-1-8">
              <div class="col-sm-12">
                <div class="float-right text-right">
                  <p>
                    Total amount (VAT Included) :
                    {{ getTotal() | number : "1.2-5" }} AED
                  </p>
                  <hr />
                  <h3>
                    <span class="font-weight-semibold text-dark">Total :</span>
                    {{ getTotal() | number : "1.2-5" }} AED
                  </h3>
                </div>
              </div>
            </div>
            <div class="row m-t-30 lh-2">
              <div class="col-sm-12">
                <div class="border top bottom p-v-20">
                  <p class="text-opacity">
                    <small>{{ globals?.preferences?.invoice_notes }}</small>
                  </p>
                  <p class="text-opacity">
                    <small
                      >This invoice is digitally signed. No physical signature
                      is required.
                    </small>
                  </p>
                </div>
              </div>
            </div>
            <div class="row m-v-20">
              <div class="col-sm-6">
                <img
                  class="img-fluid text-opacity m-t-5"
                  width="100"
                  src="assets/images/logo/qr-code.png"
                  alt=""
                />
                <div class="col-sm-4 mt-80">
                  <small
                    ><a href="https://www.dekode.dev">www.dekode.dev</a></small
                  >
                </div>
              </div>

              <div class="col-sm-6 text-right">
                <small
                  ><b>Invoiced by: </b>
                  {{ toTitleCase(invoice?.invoiced_by?.first_name) }}
                  {{ toTitleCase(invoice?.invoiced_by?.last_name) }}</small
                ><br />
                <small
                  ><span class="font-weight-semibold text-dark">Phone: </span>
                  {{ globals?.contact?.phone_number }}</small
                >
                <br />
                <small>{{ globals?.contact?.email }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-card>
  </div>
</div>
