<div class="container">
  <div class="text-right mb-3">
    <button
      nz-button
      nzType="primary"
      class="add-button"
      (click)="saveInvoice()"
      [disabled]="saved"
    >
      <i nz-icon nzType="save" nzTheme="outline"></i>
      Save
    </button>
    <button
      nz-button
      nzType="primary"
      class="add-button ml-4"
      (click)="onPrint()"
      [hidden]="!saved"
    >
      <i nz-icon nzType="printer" nzTheme="outline"></i>
      Print
    </button>
  </div>

  <nz-card>
    <div id="invoice" class="p-h-30">
      <div class="m-t-15 lh-2">
        <div class="text-center">
          <h2>NEW INVOICE</h2>
        </div>
      </div>
      <hr />
      <form [formGroup]="invoiceForm" (ngSubmit)="submitForm()">
        <div class="row m-t-10 lh-2">
          <div class="col-sm-9">
            <h5 class="p-l-10 m-t-10">Organization:</h5>
            <nz-form-item class="p-l-10 m-t-10">
              <nz-select
                nzShowSearch
                nzPlaceHolder="Select organization"
                formControlName="organization"
                (ngModelChange)="onOrganizationChange($event)"
                nzSize="small"
                style="width: 300px; min-height: 20px"
              >
                <nz-option
                  *ngFor="let org of organizations"
                  [nzValue]="org.id"
                  [nzLabel]="org.name"
                >
                  {{ org.name }}
                </nz-option>
              </nz-select>
            </nz-form-item>

            <h5 class="p-l-10 m-t-10">Invoice To:</h5>
            <address class="p-l-10 m-t-10">
              <nz-form-item>
                <nz-select
                  nzShowSearch
                  nzServerSearch
                  nzPlaceHolder="Enter name"
                  formControlName="invoiced_to"
                  [nzShowArrow]="false"
                  (nzOnSearch)="search($event)"
                  allowClear
                  nzSize="small"
                  style="width: 300px; min-height: 20px"
                >
                  <nz-option
                    *ngFor="let item of profiles"
                    [nzValue]="item?.id"
                    nzLabel="{{
                      (
                        item?.contact?.first_name +
                        ' ' +
                        item?.contact?.last_name
                      ).toUpperCase()
                    }}"
                    nzCustomContent
                  >
                    {{ item?.contact?.first_name.toUpperCase() }}
                    {{ item?.contact?.last_name.toUpperCase() }}<br />
                    {{ item?.contact?.phone_number }}
                  </nz-option>
                </nz-select>
              </nz-form-item>
            </address>
            <h5 class="p-l-10 m-t-10">Referred by:</h5>
            <address class="p-l-10 m-t-10">
              <nz-form-item>
                <nz-select
                  nzShowSearch
                  nzServerSearch
                  nzPlaceHolder="Enter name"
                  formControlName="referred_by"
                  [nzShowArrow]="false"
                  (nzOnSearch)="searchTrainers($event)"
                  allowClear
                  nzSize="small"
                  style="width: 300px"
                >
                  <nz-option
                    *ngFor="let item of trainers"
                    [nzValue]="item?.id"
                    nzLabel="{{
                      (
                        item?.contact?.first_name +
                        ' ' +
                        item?.contact?.last_name
                      ).toUpperCase()
                    }}"
                    nzCustomContent
                  >
                    {{ item?.contact?.first_name.toUpperCase() }}
                    {{ item?.contact?.last_name.toUpperCase() }}<br />
                    {{ item?.contact?.phone_number }}
                  </nz-option>
                </nz-select>
              </nz-form-item>
            </address>
          </div>

          <div class="col-sm-3">
            <div class="m-t-40">
              <div class="text-dark text-uppercase d-inline-block">
                <span class="font-weight-semibold text-dark">Test Date: </span>
              </div>
              <div class="float-right">
                <nz-date-picker
                  nzSize="small"
                  (nzFormat)="(dateFormat)"
                  formControlName="description"
                >
                </nz-date-picker>
              </div>
            </div>
            <div class="m-t-20">
              <div class="text-dark text-uppercase d-inline-block">
                <span class="font-weight-semibold text-dark">Date: </span>
              </div>
              <div class="float-right">
                <nz-date-picker
                  nzSize="small"
                  (nzFormat)="(dateFormat)"
                  formControlName="invoice_date"
                >
                </nz-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-t-20">
          <div class="col-sm-12">
            <nz-table
              #orderTable
              [nzShowPagination]="false"
              formArrayName="particulars"
              [nzData]="['']"
            >
              <thead>
                <tr>
                  <th nzWidth="10px">No.</th>
                  <th style="min-width: 200px">Particular</th>
                  <th>TCN</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr
                  *ngFor="
                    let particularForm of particulars.controls;
                    let i = index
                  "
                  [formGroup]="particularForm"
                >
                  <td nzWidth="10px">
                    <nz-form-item>
                      {{ i + 1 }}
                    </nz-form-item>
                  </td>
                  <td style="min-width: 200px">
                    <nz-form-item>
                      <nz-form-control>
                        <input
                          placeholder="Input Here"
                          nz-input
                          formControlName="title"
                          [nzAutocomplete]="auto"
                          (ngModelChange)="onChange($event, i)"
                          (keyup.enter)="onSelect($event, i)"
                        />
                        <nz-autocomplete #auto>
                          <nz-auto-option
                            *ngFor="let product of filterdproducts"
                            [nzValue]="product.id"
                            [nzLabel]="product.title"
                            >{{ product.title }}
                          </nz-auto-option>
                        </nz-autocomplete>
                      </nz-form-control>
                    </nz-form-item>
                  </td>
                  <td nzWidth="40px">
                    <nz-form-item>
                      <nz-form-control>
                        <input
                          class="passenger-input"
                          nz-input
                          placeholder="TCN"
                          formControlName="description"
                          type="text"
                        />
                      </nz-form-control>
                    </nz-form-item>
                  </td>
                  <td nzWidth="40px">
                    <nz-form-item>
                      <nz-form-control>
                        <input
                          class="passenger-input"
                          nz-input
                          placeholder="QTY"
                          formControlName="quantity"
                          type="number"
                          (change)="calculate()"
                        />
                      </nz-form-control>
                    </nz-form-item>
                  </td>
                  <td>
                    <nz-form-item>
                      <nz-form-control>
                        <input
                          class="passenger-input"
                          nz-input
                          placeholder="Rate"
                          formControlName="cost"
                        />
                      </nz-form-control>
                    </nz-form-item>
                  </td>
                  <td>
                    <nz-form-item>
                      <nz-form-control>
                        <input
                          class="passenger-input"
                          nz-input
                          placeholder="Rate"
                          formControlName="amount"
                          value="{{
                            particularForm.value.cost *
                              particularForm.value.quantity
                          }}"
                        />
                      </nz-form-control>
                    </nz-form-item>
                  </td>
                  <td>
                    <nz-form-item>
                      <i
                        nz-icon
                        nzType="minus-circle-o"
                        class="dynamic-delete-button"
                        (click)="removeField(i)"
                      ></i>
                    </nz-form-item>
                  </td>
                </tr>

                <tr>
                  <button
                    nz-button
                    nzType="dashed"
                    class="add-button mt-3"
                    (click)="addRow()"
                  >
                    <i nz-icon nzType="plus"></i>
                    Add field
                  </button>
                </tr>
              </tbody>
            </nz-table>

            <div class="row m-t-30 lh-1-8">
              <div class="col-sm-12">
                <div class="float-right text-right">
                  <p>Total amount (VAT Included): {{ subTotal }} AED</p>
                  <hr />
                  <h3>
                    <span class="font-weight-semibold text-dark">Total: </span
                    >{{ subTotal | number }} AED
                  </h3>
                </div>
              </div>
            </div>
            <div class="row m-t-30 lh-2">
              <div class="col-sm-12">
                <div class="border top bottom p-v-20">
                  <p class="text-opacity">
                    <!-- <small>{{ globals?.preferences?.invoice_notes }}</small> -->
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="row m-v-20">
              <div class="col-sm-6 mt-80">
                <small>www.dekode.dev</small>
              </div>
              <div class="col-sm-6 text-right">
                <small
                  ><span class="font-weight-semibold text-dark">Phone: </span
                  >{{ globals?.contact?.phone_number | phoneNumber : "AE"}}</small
                >
                <br />
                <small>{{ globals?.contact?.email }}</small>
              </div>
            </div> -->
          </div>
        </div>
      </form>
    </div>
  </nz-card>
</div>
